import { LinearProgress, LinearProgressProps } from "@mui/material";
import React, { useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { getGrantTemplate } from "../../api/Esop";
import FileInput from "../../components/shared/FileInput";
import { Label } from "../../components/shared/InputField";
import Tooltip from "../../components/shared/Tooltip";
import { ButtonPrimary, HStack, VStack } from "../../components/utils";
import { useAddGrantExcel } from "../../queries";
import { useCompanyStore } from "../../store";
import { ValidationObj } from "../../types/Grant";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import convertToBase64 from "../../utils/convertToBase64";
import {
  useBuybackOffers,
  useOverrideLiquidityEventEligibility,
} from "../../queries/optionBuyback";

interface GrantValidation {
  hasErrors: boolean;
  fileUploaded: boolean;
  fileValidated: boolean;
  data: ValidationObj[][];
}

function OfferImport({
  onClose = () => {},
}: Readonly<{
  onClose: () => void;
}>) {
  const { companyData } = useCompanyStore();
  const { mutate: addGrantExcel, variables } = useAddGrantExcel();

  const [transactionValidation, setTransactionValidation] =
    useState<GrantValidation>({
      fileUploaded: false,
      fileValidated: false,
      hasErrors: false,
      data: [],
    });

  const { id } = useParams();
  const _id = id ?? "";
  const { data: _buybackStateData, isPlaceholderData } = useBuybackOffers(_id);
  const { mutate: addTransaction } = useOverrideLiquidityEventEligibility();
  const [response, setResponse] = useState<[]>();
  const [statusIndicatorData, setStatusIndicatorData] =
    useState<LinearProgressProps>({ variant: "determinate", value: 0 });
  const navigate = useNavigate();
  function clearForm(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    e.currentTarget.value = "";
    setResponse(undefined);
    setTransactionValidation({
      data: [],
      fileUploaded: false,
      fileValidated: false,
      hasErrors: false,
    });
  }
  function handleChange(e: any) {
    const file = e.target.files[0];
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setStatusIndicatorData({
        ...statusIndicatorData,
        variant: "indeterminate",
      });
      convertToBase64(file).then((data) => {
        const base64 = {
          file: data as string,
          eventId: _id,
        };
        addTransaction(base64, {
          onSuccess: (data) => {
            setStatusIndicatorData({
              ...statusIndicatorData,
              variant: "determinate",
            });
            toast("Offer List Updated", {
              type: "success",
              autoClose: 3000,
            });
            onClose();
          },
          onError: (error) => {
            setStatusIndicatorData({
              ...statusIndicatorData,
              variant: "determinate",
            });
            if (
              typeof error.response.data.response === "string" &&
              error.response.data.response === "No offer to upload"
            )
              toast("No offer to upload", { type: "error" });
            if (typeof error.response.data.response === "object") {
              toast("Please correct the errors before proceeding", {
                type: "error",
              });
              const { response } = error.response.data;
              setTransactionValidation({
                hasErrors: true,
                fileUploaded: true,
                fileValidated: true,
                data: response,
              });
            }
          },
        });
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }
  const downloadTemplate = () => {
    getGrantTemplate().then((uploadTemplate) => {
      const template = uploadTemplate.data.uploadTemplate;
      convertBase64ToBlob(
        template,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ).then((blobObject) => {
        downloadBlobObject(
          blobObject,
          `${companyData?.name} Grant Upload Template.xlsx`
        );
      });
    });
  };
  return (
    <div className="bg-white rounded-lg shadow-lg max-w-4xl w-full">
      <div className="flex items-center justify-between px-6 py-4 border-b">
        <h2 className="text-xl font-semibold">Upload Offers</h2>
        <button
          onClick={onClose}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
        >
          <span className="text-gray-500">&times;</span>
        </button>
      </div>

      <div className="p-6">
        <div className="flex justify-end mb-6">
          <ButtonPrimary
            onClick={downloadTemplate}
            className="flex items-center gap-2"
          >
            <span>Download Template</span>
          </ButtonPrimary>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-4">
            <Label className="text-sm font-medium min-w-[120px]">
              Upload Excel File
            </Label>
            <div className="flex-1 flex items-center gap-4">
              <FileInput
                accept=".xlsx, .xls, .ods"
                onClick={clearForm}
                onChange={handleChange}
                multiple={false}
                type="file"
                file={"Choose File"}
                className="flex-1"
              />
              <LinearProgress
                className="flex-1 h-2"
                {...statusIndicatorData}
                color="success"
              />
            </div>
          </div>
        </div>

        {transactionValidation.fileUploaded && transactionValidation.hasErrors && (
          <div className="mt-6 border rounded-lg overflow-hidden">
            <div className="max-h-[400px] overflow-y-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {transactionValidation.data[0].map((data) => (
                      <th
                        key={data.name}
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {data.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {transactionValidation.data.map((data, index) => (
                    <tr key={index}>
                      {data.map((item) => (
                        <td key={item.name} className="px-4 py-3">
                          {item.error ? (
                            <Tooltip text={item.error}>
                              <p className="text-xs font-medium text-red-500">
                                {item.value || `{ ${item.name} }`}
                              </p>
                            </Tooltip>
                          ) : (
                            <p className="text-xs font-medium text-gray-700">
                              {item.value}
                            </p>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <div className="px-6 py-4 border-t bg-gray-50 flex justify-end gap-3">
        <button
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default OfferImport;
