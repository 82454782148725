import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import BCHeader from "../../shared/BCHeader";
import {
  MonetaryTransactionType,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import useIsMobile from "../../utils/detectDevice";
import BuybackEmployeeDetails from "./BuybackEmployeeDetails";
import BuybackEmployeeList from "./BuybackOffersList";
import BuybackDetails from "./Buybackdetails";
import ExerciseDetails from "./ExerciseDetails";
import LiquidationDetails from "./LiquidationDetails";
import { Box, VStack } from "../../components/utils";

function ViewOptionBuybackDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const _id = id ?? "";
  const { data: buybackOverview, refetch, isFetching } = useGetOptionBuyback();
  const optionBuybackOverview = buybackOverview ?? [];
  const [buyback, setBuyback] = useState<OptionBuyBackDetails>();

  useEffect(() => {
    refetch();
  }, [optionBuybackOverview]);

  useEffect(() => {
    if (!isFetching) {
      const isValidBuyBack = optionBuybackOverview.find(
        (optionBuyBack) => optionBuyBack.id === _id
      );
      if (!isValidBuyBack) {
        toast(`You don't have permission to access ${_id} buyback`, {
          type: "error",
          autoClose: 3000,
        });
        navigate("/options/esopTransactions");
      } else {
        setBuyback(isValidBuyBack);
      }
    }
  }, [optionBuybackOverview, _id, isFetching]);

  const { isMobile } = useIsMobile();

  if (!buyback) {
    return (
      <VStack className="gap-4 py-3">
        <BCHeader bcTitle="Liquidity Event Planning and Administration" />
        <Box
          className={`${
            isMobile ? "w-full" : "w-3/5"
          } px-5 py-5 border-dashed border border-[#FFC700] bg-yellow-50`}
        >
          <p className="text-sm font-medium text-black">Loading...</p>
        </Box>
      </VStack>
    );
  }
  return (
    <div className="space-y-4">
      <BCHeader
        bcTitle={"Liquidity Event Planning and Administration"}
        bcSubTitle={buyback.eventName}
      />
      <div className="p-4 bg-[#fefefe] gap-4">
        {buyback.transactionType === MonetaryTransactionType.BUYBACK && (
          <BuybackDetails buybackDetail={buyback} />
        )}
        {(buyback.transactionType === MonetaryTransactionType.EXERCISE ||
          buyback.transactionType ===
            MonetaryTransactionType.EXERCISE_LIQUIDITY) && (
          <ExerciseDetails buybackDetail={buyback} />
        )}
        {buyback.transactionType === MonetaryTransactionType.LIQUIDATION && (
          <LiquidationDetails buybackDetail={buyback} />
        )}
        <BuybackEmployeeDetails buybackDetail={buyback} />
        <div className={`w-full ${isMobile ? "overflow-x-auto" : "grow"}`}>
          <BuybackEmployeeList buyback={buyback} />
        </div>
      </div>
    </div>
  );
}

export default ViewOptionBuybackDetails;
