import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import _ from "lodash";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../../components/utils";
import {
  CheckBoxWithLabel,
  Input,
  Label,
  TextArea,
} from "../../../components/shared/InputField";
import SearchDropDown from "../../../components/shared/SearchDropdown";
import {
  GrantedBy,
  useGrantDetailsStore,
} from "../../../store/useGrantDetailsStore";
import { useValuationDetails } from "../../../queries/Valuation";
import { Valuation, ValuationSource } from "../../../types/ValuationType";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { GrantPageCard } from "./GrantByOptionsPage";
import { useGetAllCompensationModelling } from "../../../queries/compensationModelling";
import { CompensationModellingData } from "../../esopModeling/companyDetails/utils/OrgStructureJSONDataProc";
import { Select } from "../../../components/shared/Select";
import {
  getSalaryRangeForUI,
  getSingleSalaryRangeForUI,
} from "../../esopModeling/modellingUtils";

interface GrantByOptionsProps {
  onStepChange: (e?: number) => void;
  onBackClick: () => void;
}

enum ChangedValue {
  Options = "Options",
  Amount = "Amount",
  Valuation = "Valuation",
  Share = "Share",
}

const AutoGrantPage = (props: GrantByOptionsProps) => {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const data = useGrantDetailsStore();
  const { data: _models, isFetching: plansFetching } =
    useGetAllCompensationModelling();
  const { data: _valuationData, isFetching } = useValuationDetails();
  const [valuationData, setValuationData] = useState<Valuation[]>([]);
  const [optionsCheckBox, setOptionsCheckBox] = useState(false);
  const [errors, setErrors] = useState<any>({});
  useEffect(() => {
    if (_valuationData && _valuationData.length > 0) {
      const companyValuations = _valuationData.filter(
        (v) => v.valuationSource === ValuationSource.COMPANY
      );
      setValuationData([...companyValuations]);
    }
  }, [isFetching]);
  const [modellingData, setModellingData] = useState<
    CompensationModellingData[]
  >([]);
  const [selectedModellingData, setSelectedModellingData] =
    useState<CompensationModellingData>();
  useEffect(() => {
    if (_models && _models.length > 0) {
      setModellingData([..._models]);
    }
  }, [plansFetching]);

  const handleModelChange = (id: string) => {
    function getCaseInsensitiveKey(obj: any, key: any) {
      if (!obj || typeof obj !== "object") return undefined;
      const lowerCaseKey = key.toLowerCase();
      return Object.keys(obj).find((k) => k.toLowerCase() === lowerCaseKey);
    }
    const selectedModelling = modellingData.find((e) => e.id === id);

    data.setModellingId(id);
    setSelectedModellingData(selectedModelling);
    let percentageOfSalary = 0;
    let minSalary = 0;
    let maxSalary = 0;
    if (selectedModelling && selectedModelling.data && data.employee) {
      const {
        department,
        grade,
        employeeDesignation,
        employeeIdentificationString,
      } = data.employee;
      const { departments, employeeListUploaded } = selectedModelling.data;

      const employeeDeptKey = getCaseInsensitiveKey(departments, department);
      const employeeDept = departments[employeeDeptKey || ""];

      const employeeLevelKey = getCaseInsensitiveKey(
        employeeDept?.levels,
        grade || ""
      );
      const employeeLevel = employeeDept?.levels?.[employeeLevelKey || ""];

      const employeeRoleKey = getCaseInsensitiveKey(
        employeeLevel?.roles,
        employeeDesignation
      );
      const employeeRole = employeeLevel?.roles?.[employeeRoleKey || ""];

      if (employeeRole) {
        percentageOfSalary =
          employeeListUploaded &&
          employeeRole.employees?.[employeeIdentificationString]
            ? employeeRole.employees[employeeIdentificationString]
                .percentageOfSalary
            : employeeRole.percentageOfSalary;
        minSalary =
          employeeListUploaded &&
          employeeRole.employees?.[employeeIdentificationString]
            ? employeeRole.employees[employeeIdentificationString].salary
            : employeeRole.salaryRange.min;
        maxSalary =
          employeeListUploaded &&
          employeeRole.employees?.[employeeIdentificationString]
            ? 0
            : employeeRole.salaryRange.max;
      }
    }
    const options = selectedModelling?.data?.employeeListUploaded
      ? minSalary * (percentageOfSalary / 100)
      : ((minSalary + maxSalary) / 2) * (percentageOfSalary / 100);
    data.setOptionMultiplier(percentageOfSalary);
    data.setMinSalary(minSalary);
    data.setMaxSalary(maxSalary);
    data.setModelValueOfOptions(
      selectedModelling?.data?.employeeListUploaded
        ? getSingleSalaryRangeForUI(
            minSalary * (percentageOfSalary / 100),
            currencySymbol,
            currencyType
          )
        : getSalaryRangeForUI(
            {
              min: minSalary * (percentageOfSalary / 100),
              max: maxSalary * (percentageOfSalary / 100),
            },
            currencySymbol,
            currencyType
          )
    );
    data.setModelMaxValueOfOptions(
      selectedModelling?.data?.employeeListUploaded
        ? minSalary * (percentageOfSalary / 100)
        : maxSalary * (percentageOfSalary / 100)
    );
    data.setOptionsGranted(
      data.sharePrice > 0 && data.valueOfOptions > 0
        ? data.plan.isFractional
          ? parseFloat((data.valueOfOptions / data.sharePrice).toFixed(4))
          : parseInt((data.valueOfOptions / data.sharePrice).toString(), 10)
        : 0
    );
  };
  const validationSchema = Yup.object().shape({
    noOfOptions: Yup.number()
      .required("No of Options is required")
      .min(
        data?.plan?.isFractional ? 0.0001 : 1,
        `No of Options must be greater than ${
          data?.plan?.isFractional ? 0.0001 : 1
        }`
      )
      .max(
        data?.plan?.optionsReserved,
        "No of Options must be lesser than available options"
      ),
    modellingId: Yup.string().required("Model is Required"),
    optionsCheckBox: Yup.boolean()
      .oneOf([true], "You must accept the options")
      .required("You must accept the options"),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {},
  });

  function handleSubmit() {
    formik
      .validateForm({
        noOfOptions: data.optionsGranted,
        modellingId: data.modellingId,
        optionsCheckBox,
      })
      .then((validationErrors) => {
        if (_.isEmpty(validationErrors)) {
          props.onStepChange();
        } else {
          setErrors(validationErrors);
        }
      });
  }

  useEffect(() => {
    if (data.modellingId && data.modellingId !== "")
      handleModelChange(data.modellingId);
  }, [data.modellingId, modellingData]);

  function handleChange(value: number, changedValue: ChangedValue) {
    if (changedValue === ChangedValue.Options) {
      data.setOptionsGranted(value);
      if (value >= 0 && (data.sharePrice || 0) >= 0) {
        data.setValueOfOptions(value * (data.sharePrice || 0));
      }
    } else if (changedValue === ChangedValue.Amount) {
      data.setValueOfOptions(value);
      if (data.valueOfOptions >= 0 && data.sharePrice >= 0) {
        data.setOptionsGranted(
          data?.plan.isFractional
            ? parseFloat((value / data.sharePrice).toFixed(4))
            : parseInt((value / data.sharePrice).toString(), 10)
        );
      }
    } else if (changedValue === ChangedValue.Share) {
      data.setSharePrice(value);
      if (value >= 0 && (data.valueOfOptions || 0) >= 0) {
        data.setOptionsGranted(
          data?.plan.isFractional
            ? parseFloat((data.valueOfOptions / value).toFixed(4))
            : parseInt((data.valueOfOptions / value).toString(), 10)
        );
      }
    }
  }

  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Grant Information
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit.
        </HStack>
      </VStack>
      <HStack className="gap-4 p-4">
        <GrantPageCard
          header="Options Available"
          value={(data?.plan?.optionsReserved || 0).toLocaleString(
            currencyType
          )}
        />
        <GrantPageCard header="Conversion Ratio" value={data.conversionRatio} />
      </HStack>
      <VStack className="gap-8 px-6">
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Choose Model to Grant</Label>
            <Select
              placeholder="--Select--"
              options={modellingData}
              textGetter={(e) => e.modelName || ""}
              valueGetter={(e) => e.id || ""}
              value={data.modellingId || ""}
              onChange={(e) => handleModelChange(e.target.value)}
            />
            {errors.modellingId && <Error text={errors.modellingId} />}
          </div>
          <div className="flex-1"></div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1 cursor-not-allowed">
            <Label className="text-sm font-normal cursor-not-allowed">
              Option Multiplier (%)
            </Label>
            <Input
              type="number"
              value={data.optionMultiplier}
              disabled={true}
            />
          </div>
          <div className="flex-1 cursor-not-allowed">
            <Label className="text-sm font-normal">
              Salary of Employee ({currencySymbol})
            </Label>
            {selectedModellingData?.data?.employeeListUploaded ? (
              <Input
                className="cursor-not-allowed"
                type="number"
                value={data.minSalary}
                disabled={true}
              />
            ) : (
              <HStack className="items-center gap-2">
                <Label className="text-sm font-normal cursor-not-allowed">
                  min
                </Label>
                <Input type="number" value={data.minSalary} disabled={true} />
                <Label className="text-sm font-normal cursor-not-allowed">
                  max
                </Label>
                <Input type="number" value={data.maxSalary} disabled={true} />
              </HStack>
            )}
          </div>
        </HStack>
        <HStack className="">
          <p>
            Based on the model selected above, the value of <b>“Options”</b>{" "}
            allocated for the employee are as follows
          </p>
        </HStack>
        <HStack className="justify-center">
          <GrantPageCard
            header={`Value of Options (${currencySymbol})`}
            value={data.modelValueOfOptions}
          />
        </HStack>
        <HStack className="text-xxs text-[#668DB1] gap-2 mx-20 p-4 items-center bg-[#EAF0FA]">
          <Icon icon="ep:warning" color="#668DB1" height={20} />
          <p>
            The above calculated options are based on the data entered during
            the construction of the compensation model. Please use the data
            accordingly.
          </p>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Value of Options Granted ({currencySymbol})
            </Label>
            <Box
              className={`${
                data.modelMaxValueOfOptions < data.valueOfOptions
                  ? " border border-orange-500 rounded-md"
                  : ""
              }`}
            >
              <Input
                type="number"
                onChange={(e) => {
                  const value = parseFloat(Number(e.target.value).toFixed(4));
                  handleChange(value, ChangedValue.Amount);
                }}
                placeholder={`${
                  data.valueOfOptions > 0 ? "" : "Enter value of options"
                }`}
                value={data.valueOfOptions === 0 ? "" : data.valueOfOptions}
              />
            </Box>
            {data.modelMaxValueOfOptions < data.valueOfOptions && (
              <div className="text-orange-500">
                {
                  "This is higher than the suggested amount, approval may be required"
                }
              </div>
            )}
          </div>
          <div className="flex-1"></div>
        </HStack>
        <VStack>
          <CheckBoxWithLabel
            label={`The above number of "Options" granted has been approved`}
            labelclassname={`ml-2 text-sm font-normal items-center`}
            onChange={() => {
              setOptionsCheckBox(!optionsCheckBox);
            }}
          />
          {errors.optionsCheckBox && <Error text={errors.optionsCheckBox} />}
        </VStack>
        <HStack>
          <div className="flex-1">
            <Label className="text-sm font-normal">Notes</Label>
            <TextArea value={""} />
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Valuation Reports</Label>
            <Select
              onChange={(e) => {
                const valuation = valuationData.find(
                  (v) => v.id === e.target.value
                );
                data.setValuationId(e.target.value);
                handleChange(valuation?.pps || 0, ChangedValue.Share);
              }}
              placeholder="--Select--"
              options={valuationData}
              value={data.valuationId}
              textGetter={(e) => e.name}
              valueGetter={(e) => e.id}
              className={""}
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Share Price ({currencySymbol})
            </Label>
            <Input
              type="number"
              disabled={data.valuationId !== ""}
              onChange={(e) => {
                const value = parseFloat(
                  Number(e.target.value === "" ? "0" : e.target.value).toFixed(
                    4
                  )
                );
                handleChange(value, ChangedValue.Share);
              }}
              placeholder={`${data.sharePrice > 0 ? "" : "Enter share price"}`}
              value={data.sharePrice === 0 ? "" : data.sharePrice}
              min={0}
            />
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">No. of Options</Label>
            <Input
              type="number"
              disabled={true}
              onChange={(e) => {
                const value = data.plan.isFractional
                  ? parseFloat(Number(e.target.value).toFixed(4))
                  : parseInt(e.target.value, 10);
                handleChange(value, ChangedValue.Options);
              }}
              value={data.optionsGranted || 0}
            />
            {errors.noOfOptions && <Error text={errors.noOfOptions} />}
          </div>

          <div className="flex-1"></div>
        </HStack>

        <HStack className="justify-between pt-4">
          <ButtonPrimary1 onClick={props.onBackClick}>Back</ButtonPrimary1>
          <ButtonPrimary onClick={handleSubmit}>Next</ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default AutoGrantPage;
