import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, VStack } from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import BCHeader from "../../shared/BCHeader";
import {
  MonetaryTransactionType,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import useIsMobile from "../../utils/detectDevice";
import BuybackEmployeeDetails from "./BuybackEmployeeDetails";
import BuybackEmployeeList from "./BuybackOffersList";
import BuybackDetails from "./Buybackdetails";
import ExerciseDetails from "./ExerciseDetails";

function CompleteBuyback() {
  const { id } = useParams();
  const _id = id ?? "";
  const navigate = useNavigate();
  const { data: buybackOverview, refetch, isFetching } = useGetOptionBuyback();
  const optionBuybackOverview = buybackOverview ?? [];
  const [buyback, setBuyback] = useState<OptionBuyBackDetails>();
  useEffect(() => {
    refetch();
    if (!isFetching) {
      const isValidBuyBack = optionBuybackOverview.find(
        (optionBuyBack) => optionBuyBack.id === _id
      );
      if (!isValidBuyBack) {
        toast(`You don't have permission to access ${_id} buyback`, {
          type: "error",
          autoClose: 3000,
        });
        navigate("/options/esopTransactions");
      } else {
        setBuyback(isValidBuyBack);
      }
    }
  }, [optionBuybackOverview, _id]);
  const { isMobile } = useIsMobile();

  if (!buyback) {
    return (
      <VStack className="gap-4 py-3">
        <BCHeader bcTitle="Liquidity Event Planning and Administration" />
        <Box
          className={`${
            isMobile ? "w-full" : "w-3/5"
          } px-5 py-5 border border-green-400 border-dashed bg-green-50`}
        >
          <p className="text-sm font-medium text-black">Loading...</p>
        </Box>
      </VStack>
    );
  }

  return (
    <div className="space-y-4">
      <BCHeader
        bcTitle="Liquidity Event Planning and Administration"
        bcSubTitle={buyback?.eventName}
      />
      <Box
        className={`${
          isMobile ? "w-full" : "w-3/5"
        } px-5 py-5 border border-green-400 border-dashed bg-green-50`}
      >
        <p className="text-sm font-medium text-black">
          {`${
            buyback?.transactionType !== MonetaryTransactionType.BUYBACK
              ? "Liquidity programme/program"
              : "Options"
          }  ${
            buyback?.transactionType === MonetaryTransactionType.BUYBACK
              ? "Buy Back"
              : ""
          } ${buyback?.eventState.toLowerCase()} successfully`}
        </p>
      </Box>
      <div className="flex flex-col gap-4 bg-[#fefefe]">
        {(buyback?.transactionType === MonetaryTransactionType.EXERCISE ||
          buyback?.transactionType ===
            MonetaryTransactionType.EXERCISE_LIQUIDITY) && (
          <ExerciseDetails buybackDetail={buyback} />
        )}
        {buyback?.transactionType === MonetaryTransactionType.BUYBACK && (
          <BuybackDetails buybackDetail={buyback} />
        )}
        {buyback && <BuybackEmployeeDetails buybackDetail={buyback} />}
        {buyback && <BuybackEmployeeList buyback={buyback} />}
      </div>
    </div>
  );
}

export default CompleteBuyback;
