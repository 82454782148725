import React from "react";
import { Box, HStack } from "../../components/utils";
import GrantsTable from "../esopOverview/GrantsTable";
import VestingSchedule from "./VestingSchedule";
import useIsMobile from "../../utils/detectDevice";

function GrantsVSGrade() {
  const { isMobile } = useIsMobile();
  return (
    <>
      <Box className="my-6">
        <Box className={`${isMobile ? "w-full overflow-x-auto" : "grow"} `}>
          <GrantsTable />
        </Box>
      </Box>
      <Box>
        <VestingSchedule />
      </Box>
    </>
  );
}

export default GrantsVSGrade;
