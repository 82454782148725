/* eslint-disable func-names */
import React, { useEffect, useMemo, useState } from "react";
import { ChartData } from "chart.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Dialog, Switch } from "@mui/material";
import { Icon } from "@iconify/react";
import { addMonths, format } from "date-fns";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../../components/utils";
import { Input, Label } from "../../../components/shared/InputField";
import {
  GrantedBy,
  useGrantDetailsStore,
  VestingScheduleData,
} from "../../../store/useGrantDetailsStore";
import SearchDropDown from "../../../components/shared/SearchDropdown";
import {
  useEmployees,
  useEsopPlans,
  useGetVestingOverrides,
  useVestingTemplates,
} from "../../../queries";
import { EmployementStatus } from "../../../types/Employee";
import { useCompanyStore } from "../../../store";
import canUserBeAssignedToThisPlan from "../../../utils/grantRule";
import { GrantPageCard } from "../grantInformation/GrantByOptionsPage";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import {
  extractDateFromTimeStamp,
  formatDisplayDate,
} from "../../../utils/date";
import { VestingDateType } from "../../../types/Grant";
import { Select } from "../../../components/shared/Select";
import {
  AddVestingMilestoneReq,
  VestingMilestone,
} from "../../../types/milestone";
import { useGetAllMilestones } from "../../../queries/milestone";
import AddOrEditMilestone from "../../milestones/AddOrEditMilestone";
import { TriggerType, VestingType } from "../../../types/VestingTemplate";
import { BarChart } from "../../vestingSchedules/BarChart";

interface GrantVestingScheduleProps {
  onStepChange: () => void;
  onBackClick: () => void;
}

const GrantVestingSchedule = (props: GrantVestingScheduleProps) => {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const data = useGrantDetailsStore();
  const [errors, setErrors] = useState<any>({});
  const { data: availableVestingOverrides } = useGetVestingOverrides();
  const { data: _allMilestones, isFetched } = useGetAllMilestones();
  const { data: vestingTemplates } = useVestingTemplates();
  const [includeMilestone, setIncludeMilestone] = useState(() => {
    if (
      data &&
      data.vestingScheduleData &&
      data.vestingScheduleData.some((v) => v.milestoneId !== "")
    ) {
      return true;
    } else return false;
  });
  const [allMilestones, setAllMilestones] = useState<VestingMilestone[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [dialog, setDialog] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  useEffect(() => {
    setAllMilestones(_allMilestones || []);
  }, [isFetched]);
  const vestingScheduleTemplates = vestingTemplates?.filter(
    (schedule) => !schedule.isDefault
  );
  const vestingTypes = [
    {
      description: "Vesting From date of Grant",
      value: VestingDateType.GRANT_DATE,
    },
    {
      description: "Vesting From date of Joining",
      value: VestingDateType.EMPLOYEE_JOINING_DATE,
    },
    { description: "Custom Vesting", value: VestingDateType.CUSTOM_DATE },
  ];
  const availableActualVestingDays: string[] = useMemo(
    () => availableVestingOverrides?.data,
    [availableVestingOverrides]
  );

  const validationSchema = Yup.object().shape({
    selectedExistingTemplate: Yup.boolean().required(
      "No of Options is required"
    ),
    selectedVestingTemplate: Yup.string().when("selectedExistingTemplate", {
      is: true,
      then: Yup.string().required(
        "Vesting Template is required when selecting an existing template"
      ),
    }),
    vestingScheduleData: Yup.array()
      .of(
        Yup.object().shape({
          vestingDate: Yup.string().required("Vesting Date is required"),
          percentage: Yup.number().required("Percentage is required"),
          vestingOptions: Yup.number().required("Vesting Options are required"),
          milestoneId: Yup.string().optional(),
        })
      )
      .when("selectedExistingTemplate", {
        is: false,
        then: Yup.array()
          .required("Vesting Schedule Data is required")
          .test(
            "options-match",
            "Sum of Vesting Options must match Options Granted",
            function (vestingScheduleData) {
              const { optionsGranted } = this.parent;

              if (!vestingScheduleData || !optionsGranted) return true;
              const totalVestingOptions = vestingScheduleData.reduce(
                (sum, item) => sum + (item.vestingOptions || 0),
                0
              );

              return totalVestingOptions === optionsGranted;
            }
          ),
      })
      .test(
        "vesting-date-check",
        "Vesting Date cannot be earlier than the Grant Date",
        function (vestingScheduleData) {
          const { dateOfGrant } = this.parent;

          if (!vestingScheduleData || !dateOfGrant) return true;

          const isValid = vestingScheduleData.every((item) => {
            const vestingDate = new Date(item?.vestingDate || new Date());
            const grantDateObj = new Date(dateOfGrant);

            return vestingDate >= grantDateObj;
          });

          return isValid;
        }
      ),
  });

  useEffect(() => {
    if (
      data.vestingTemplate &&
      data.vestingTemplate.id &&
      data.vestingTemplate.id !== "" &&
      data.vestingTemplate.vestingType !== VestingType.MANUAL
    ) {
      handleChangeOfVestingTemplateForExisting(data.vestingTemplate.id);
    }
  }, [data?.vestingTemplate]);

  const handleChangeOfVestingTemplateForManual = (e: string) => {
    const value = vestingScheduleTemplates?.find((v) => v.id === e);
    if (value) {
      data.setTemplateToEdit(value);
      const vestingScheduleData: VestingScheduleData[] = generateProjections(
        value.schedules,
        new Date(data.dateOfGrant || new Date()),
        value.cliffPeriod,
        data.optionsGranted || 1,
        data.plan?.isFractional || false
      );
      data.setVestingScheduleData(
        vestingScheduleData.filter((v) => v.vestingOptions > 0)
      );
    }
  };

  const handleChangeOfVestingTemplateForExisting = (e: string) => {
    const value = vestingScheduleTemplates?.find((v) => v.id === e);
    if (value) {
      data.setVestingTemplate(value);
    }
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {},
  });

  function handleSubmit() {
    formik
      .validateForm({
        selectedExistingTemplate: data.selectedExistingTemplate,
        selectedVestingTemplate: data?.vestingTemplate?.id || "",
        optionsGranted: data?.optionsGranted,
        vestingScheduleData: data?.vestingScheduleData,
        dateOfGrant: data?.dateOfGrant,
      })
      .then((validationErrors) => {
        if (_.isEmpty(validationErrors)) {
          props.onStepChange();
        } else {
          setErrors(validationErrors);
        }
      });
  }

  function getVestingDate() {
    const vestingDate =
      !data.vestingDateType ||
      data.vestingDateType === VestingDateType.GRANT_DATE
        ? new Date(data.dateOfGrant)
        : data.vestingDateType === VestingDateType.EMPLOYEE_JOINING_DATE
        ? new Date(data.employee.dateOfJoin)
        : new Date(data.vestingDate);

    if (!data.actualVestingDay || data.actualVestingDay === "SAME_DAY") {
      return vestingDate;
    } else if (data.actualVestingDay === "NEXT_DAY") {
      vestingDate.setDate(vestingDate.getDate() + 1);
      return vestingDate;
    } else if (data.actualVestingDay === "PREVIOUS_DAY") {
      vestingDate.setDate(vestingDate.getDate() - 1);
      return vestingDate;
    } else if (data.actualVestingDay === "STARTING_OF_MONTH") {
      vestingDate.setDate(1);
      return vestingDate;
    } else if (data.actualVestingDay === "END_OF_MONTH") {
      vestingDate.setMonth(vestingDate.getMonth() + 1);
      vestingDate.setDate(0);
      return vestingDate;
    }
    return vestingDate;
  }

  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Vesting Schedule
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro
          blanditiis doloribus
        </HStack>
      </VStack>
      <HStack className="gap-4 p-4">
        <GrantPageCard
          header="Grant Date"
          value={formatDisplayDate(data.dateOfGrant || new Date())}
        />
        <GrantPageCard
          header="Granted Options"
          value={(data.optionsGranted || 0).toLocaleString(currencyType)}
        />
        <GrantPageCard
          header="Options Available"
          value={(data?.plan?.optionsReserved || 0).toLocaleString(
            currencyType
          )}
        />
        <GrantPageCard header="Conversion Ratio" value={data.conversionRatio} />
      </HStack>
      <VStack className="gap-4 px-6 pt-7">
        <HStack className="gap-8 p ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Choose Template Type</Label>
            <Select
              placeholder="--Select--"
              options={["Existing Template", "Manually Create Template"]}
              value={
                data?.selectedExistingTemplate
                  ? "Existing Template"
                  : "Manually Create Template"
              }
              onChange={(e) => {
                data.setSelectedExistingTemplate(
                  e.target.value === "Existing Template"
                );
              }}
            />
            {errors.selectedVestingTemplate && (
              <Error text={errors.selectedVestingTemplate} />
            )}
          </div>
          {data.selectedExistingTemplate ? (
            <div className="flex-1"></div>
          ) : (
            <div className="flex-1">
              <Label className="text-sm font-normal">Choose Template</Label>
              <Select
                placeholder="--Select template to edit--"
                options={vestingScheduleTemplates || []}
                value={data?.templateToEdit?.id || ""}
                textGetter={(e) => e.vestingTemplateName}
                valueGetter={(e) => e.id}
                onChange={(e) =>
                  handleChangeOfVestingTemplateForManual(e.target.value)
                }
              />
            </div>
          )}
        </HStack>
        {data.selectedExistingTemplate ? (
          <>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Vesting Schedule</Label>
                <Select
                  placeholder="--Select--"
                  options={vestingScheduleTemplates || []}
                  value={data?.vestingTemplate?.id || ""}
                  textGetter={(e) => e.vestingTemplateName}
                  valueGetter={(e) => e.id}
                  onChange={(e) => {
                    handleChangeOfVestingTemplateForExisting(e.target.value);
                  }}
                />
                {errors.vestingTemplate && (
                  <Error text={errors.vestingTemplate} />
                )}
              </div>
              <div className="flex-1"></div>
            </HStack>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Vesting Date Type</Label>
                <Select
                  options={vestingTypes}
                  value={data.vestingDateType || ""}
                  textGetter={(option) => option?.description}
                  valueGetter={(value) => value?.value}
                  onChange={(e) => {
                    const type = e.target.value as VestingDateType;
                    data.setVestingDateType(type);
                    if (type === VestingDateType.CUSTOM_DATE) {
                      data.setActualVestingDay("SAME DAY");
                      data.setVestingDate(data.dateOfGrant);
                    } else {
                      data.setVestingDate("");
                    }
                  }}
                />
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Actual Vesting Day
                </Label>
                <Select
                  options={availableActualVestingDays || []}
                  value={data.actualVestingDay}
                  isPlaceHolderDisabled={true}
                  textGetter={(option) => option.split("_").join(" ")}
                  valueGetter={(value) => value}
                  disabled={
                    data.vestingDateType === VestingDateType.CUSTOM_DATE
                  }
                  onChange={(e) => {
                    if (data.vestingDateType === VestingDateType.CUSTOM_DATE)
                      return;
                    data.setActualVestingDay(e.target.value);
                  }}
                />
              </div>
            </HStack>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label
                  className={`text-sm font-normal ${
                    data.vestingDateType !== VestingDateType.CUSTOM_DATE &&
                    "text-gray-400"
                  }`}
                >
                  Select a Date
                </Label>
                <Input
                  type="date"
                  disabled={
                    data.vestingDateType !== VestingDateType.CUSTOM_DATE
                  }
                  name="vestingDate"
                  value={data.vestingDate}
                  onChange={(e) => {
                    if (e.target.value !== "")
                      data.setVestingDate(
                        format(new Date(e.target.value), "yyyy-MM-dd")
                      );
                  }}
                />
              </div>
              <div className="flex-1"></div>
            </HStack>
          </>
        ) : (
          <>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Template Name</Label>
                <Input
                  type="text"
                  onChange={(e) => data.setManualTemplateName(e.target.value)}
                  value={data.manualTemplateName}
                />
              </div>
              <div className="items-center justify-between flex-1">
                <HStack className="flex-row-reverse items-center justify-around pt-6">
                  <Switch
                    checked={includeMilestone}
                    onChange={() => {
                      setIncludeMilestone(!includeMilestone);
                      if (
                        data.vestingScheduleData &&
                        data.vestingScheduleData.length > 0
                      ) {
                        const vestingData = data.vestingScheduleData.map(
                          (v) => {
                            v.milestoneId = "";
                            return v;
                          }
                        );
                        data.setVestingScheduleData(vestingData);
                      }
                    }}
                    value={includeMilestone}
                  />
                  <Label className="text-sm font-normal">
                    Include Milestone Vesting
                  </Label>
                </HStack>
              </div>
            </HStack>
            {includeMilestone && (
              <HStack className="text-xxs text-[#668DB1] gap-2 mx-20 p-4 items-center bg-[#EAF0FA]">
                <Icon icon="ep:warning" color="#668DB1" height={20} />
                <p>
                  The above calculated options are based on the data entered
                  during the construction of the compensation model. Please use
                  the data accordingly.{" "}
                  <button onClick={() => setDialog({ open: !dialog.open })}>
                    <b>Create Milestone</b>
                  </button>
                </p>
              </HStack>
            )}
            <VStack className="pt-10">
              <ManualSchedulesData includeMilestone={includeMilestone} />
              {errors.vestingScheduleData && (
                <Error text={errors.vestingScheduleData} />
              )}
            </VStack>
            <HStack>
              <ButtonPrimary1
                onClick={() =>
                  data.addVestingScheduleData({
                    percentage: 0,
                    vestingDate: format(new Date(), "yyyy-MM-dd"),
                    vestingOptions: 0,
                    milestoneId: "",
                  })
                }
              >
                Add Schedule
              </ButtonPrimary1>
            </HStack>
          </>
        )}
        <Dialog open={dialog.open} maxWidth="md">
          <AddOrEditMilestone
            onClose={() => setDialog({ open: false })}
            data={undefined}
            mode="Add"
          />
        </Dialog>

        {((data.selectedExistingTemplate &&
          data.vestingTemplate &&
          data.vestingTemplate.schedules) ||
          (!data.selectedExistingTemplate &&
            data.vestingScheduleData &&
            data.vestingScheduleData.length > 0)) && (
          <VStack className="flex">
            <HStack className="flex justify-end">
              {!showTable ? (
                <button
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  <Icon
                    className="hover:text-orange-501"
                    icon="mdi:table"
                    height={25}
                  />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  <Icon
                    icon="mdi:graph-bar"
                    className="hover:text-orange-501"
                    height={25}
                  />
                </button>
              )}
            </HStack>
            <HStack className="items-center pt-20 mb-10 px-44 grow">
              {!showTable ? (
                <ProjectionChartForVesting
                  vestings={
                    data.selectedExistingTemplate
                      ? generateProjections(
                          data.vestingTemplate.schedules,
                          getVestingDate(),
                          data.vestingTemplate.cliffPeriod,
                          data.optionsGranted,
                          data.plan.isFractional
                        )
                      : data.vestingScheduleData
                  }
                  isFractional={data.plan?.isFractional || false}
                />
              ) : (
                <AGGridEmployeeScheduleTable
                  data={
                    data.selectedExistingTemplate
                      ? generateProjections(
                          data.vestingTemplate.schedules,
                          getVestingDate(),
                          data.vestingTemplate.cliffPeriod,
                          data.optionsGranted,
                          data.plan.isFractional
                        )
                      : data.vestingScheduleData
                  }
                  optionsGranted={data.optionsGranted || 0}
                />
              )}
            </HStack>
          </VStack>
        )}

        <HStack className="justify-between pt-4">
          <ButtonPrimary1 onClick={props.onBackClick}>Back</ButtonPrimary1>
          <ButtonPrimary onClick={handleSubmit}>Next</ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
};

function AGGridEmployeeScheduleTable(props: {
  data: VestingScheduleData[];
  optionsGranted: number;
}) {
  const { data, optionsGranted } = props;
  const rowData = useMemo(
    () =>
      data.map((d) => ({
        ...d,
        grantedOptions: optionsGranted,
      })),
    [data]
  );
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      menuTabs: ["filterMenuTab"],
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "VESTING DATE",
        field: "vestingDate",
        sortable: true,
        autoHeight: true,
        wrapText: true,
        minWidth: 200,
        width: 200,
        filterValueGetter: (e) => new Date(e.getValue("vestingDate")).getDate(),
        filter: "agDateColumnFilter",
        suppressAutoSize: false,
        suppressSizeToFit: true,
        valueFormatter: (e) => formatDisplayDate(e.value),
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "GRANTED",
        field: "grantedOptions",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        filter: "agNumberColumnFilter",
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "VESTED",
        field: "vestingOptions",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        filter: "agMultiColumnFilter",
      },
    ],
    []
  );

  return (
    <HStack className="justify-between w-full ">
      <Box
        style={{
          height: `${Math.min(
            400,
            (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
          )}px`,
        }}
        className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
      >
        <AgGridReact
          rowData={rowData}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
        />
      </Box>
    </HStack>
  );
}

function ManualSchedulesData({ includeMilestone = false }) {
  const data = useGrantDetailsStore();

  const { data: _allMilestones, isFetched, isFetching } = useGetAllMilestones();
  const [allMilestones, setAllMilestones] = useState<VestingMilestone[]>([]);

  useEffect(() => {
    if (_allMilestones) {
      setAllMilestones(_allMilestones);
    }
  }, [isFetched, data, isFetching]);

  return (
    <VStack
      aria-label="header"
      className="pt-4 border border-gray-200 rounded-sm "
    >
      <HStack className="p-2 gap-x-10">
        {includeMilestone && (
          <div className="flex-1 pl-4">
            <Label className="text-sm font-normal">Milestone Name</Label>
          </div>
        )}
        <div className="flex-1 pl-4">
          <Label className="text-sm font-normal">DATE</Label>
        </div>
        <div className="flex-1 pl-4">
          <Label className="text-sm font-normal">PERCENTAGE (%)</Label>
        </div>
        <div className="flex-1 pl-4">
          <Label className="text-sm font-normal">NO. OF OPTIONS</Label>
        </div>
        <div className="justify-center flex-1/10"></div>
      </HStack>
      {data.vestingScheduleData?.map((_, index) => {
        const scheduleData = data.vestingScheduleData[index];
        return (
          <HStack
            className="p-2 border-t border-gray-200 border-dashed gap-x-10"
            key={index}
          >
            {includeMilestone && (
              <div className="flex-1">
                <Select
                  placeholder="Select a milestone"
                  value={scheduleData.milestoneId || ""}
                  options={allMilestones || []}
                  valueGetter={(o) => o.id}
                  textGetter={(o) => o.milestoneName}
                  onChange={(e) => {
                    data.changeVestingScheduleData(
                      index,
                      e.target.value,
                      "milestoneId"
                    );
                    if (e.target.value !== "") {
                      const milestone = allMilestones.find(
                        (m) => m.id === e.target.value
                      );
                      if (milestone) {
                        data.changeVestingScheduleData(
                          index,
                          new Date(milestone.targetDate).toLocaleDateString(
                            "en-CA"
                          ),
                          "vestingDate"
                        );
                      }
                    }
                  }}
                />
              </div>
            )}
            <div className="flex-1">
              <Input
                type="date"
                value={scheduleData.vestingDate}
                disabled={scheduleData.milestoneId !== ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value !== "")
                    data.changeVestingScheduleData(index, value, "vestingDate");
                }}
              />
            </div>
            <div className="flex-1">
              <Input
                type="number"
                value={scheduleData.percentage}
                onChange={(e) => {
                  const optionsGranted = data?.optionsGranted || 0;
                  const options = data?.plan?.isFractional
                    ? parseFloat(
                        (
                          (optionsGranted * parseFloat(e.target.value)) /
                          100
                        ).toFixed(4)
                      )
                    : parseInt(
                        (
                          (optionsGranted * parseFloat(e.target.value)) /
                          100
                        ).toFixed(4),
                        10
                      );
                  data.changeVestingScheduleData(
                    index,
                    parseFloat(e.target.value),
                    "percentage"
                  );
                  data.changeVestingScheduleData(
                    index,
                    options,
                    "vestingOptions"
                  );
                }}
              />
            </div>
            <div className="flex-1">
              <Input
                type="number"
                value={scheduleData.vestingOptions}
                onChange={(e) => {
                  const optionsGranted = data?.optionsGranted || 0;
                  const percentage = parseFloat(
                    (
                      (parseFloat(e.target.value) / optionsGranted) *
                      100
                    ).toFixed(4)
                  );
                  data.changeVestingScheduleData(
                    index,
                    data?.plan?.isFractional
                      ? parseFloat(e.target.value)
                      : parseInt(e.target.value, 10),
                    "vestingOptions"
                  );
                  data.changeVestingScheduleData(
                    index,
                    percentage,
                    "percentage"
                  );
                }}
              />
            </div>

            <HStack className="justify-center flex-1/10">
              <button
                onClick={() => data.deleteVestingScheduleData(index)}
                className="justify-center text-zinc-300 hover:scale-105"
              >
                <DeleteIcon />
              </button>
            </HStack>
          </HStack>
        );
      })}
    </VStack>
  );
}

function ProjectionChartForVesting({
  vestings,
  isFractional,
}: {
  vestings: VestingScheduleData[];
  isFractional: boolean;
}) {
  let totalOptions = 0;
  const date = new Date();
  const vestingDataSets = vestings.map((v) => {
    const backGroundColor =
      new Date(v.vestingDate) < date ? "#97C2E8" : "#D8E3F6";
    totalOptions += v.vestingOptions;
    return {
      options: totalOptions,
      backGroundColor,
    };
  });
  const data: ChartData<"bar", number[], unknown> = {
    labels: vestings.map((v) => format(new Date(v.vestingDate), "MMM yy")),
    datasets: [
      {
        label: "to date",
        data: vestingDataSets.map((v) => v.options),
        backgroundColor: vestingDataSets.map((v) => v.backGroundColor),
      },
    ],
  };

  return <BarChart data={data} isFractional={isFractional} />;
}

function generateProjections(
  schedules: any,
  vestingStartDate: Date,
  cliffPeriod: number,
  optionsGranted: number,
  isFractional: boolean
) {
  schedules.sort((a: any, b: any) => a.srNo - b.srNo);
  const vestings: VestingScheduleData[] = [];
  let accumulatedVestingPercentageBeforeCliff = 0;
  let monthsPassed = 0;
  let vestedOptions = 0;
  let vestingPercentage = 0;
  let accumulatedVestedOptionsForGrant = 0;
  let accumulatedVestingPercentageForGrant = 0;
  let vestedOptionsSoFarForGrant = 0;
  let vestingDate = vestingStartDate;
  const baseVesting: VestingScheduleData = {
    vestingOptions: 0,
    vestingDate: format(new Date(), "yyyy-MM-dd"),
    percentage: 0,
    milestoneId: "",
  };
  schedules.forEach((schedule: any) => {
    const percentage = schedule.percentage / 100;
    if (schedule.vestingTriggerType === TriggerType.TIME) {
      const vestingPeriod = schedule.vestingDuration;
      const vestingInterval = schedule.vestingInterval;
      let noOfVestEventsForGrant = 1;
      if (vestingPeriod !== 0 && vestingInterval !== 0) {
        noOfVestEventsForGrant = vestingPeriod / vestingInterval;
      }
      let vestingEvent = 0;
      while (vestingEvent < noOfVestEventsForGrant) {
        const vesting = { ...baseVesting };
        monthsPassed += vestingInterval;
        if (monthsPassed < cliffPeriod) {
          accumulatedVestingPercentageBeforeCliff +=
            percentage / noOfVestEventsForGrant;
        } else {
          vestingPercentage =
            percentage / noOfVestEventsForGrant +
            accumulatedVestingPercentageBeforeCliff;
          accumulatedVestingPercentageBeforeCliff = 0;
          accumulatedVestingPercentageForGrant += vestingPercentage;
          if (isFractional) {
            accumulatedVestedOptionsForGrant = parseFloat(
              (accumulatedVestingPercentageForGrant * optionsGranted).toFixed(4)
            );
            vestedOptions = parseFloat(
              (
                accumulatedVestedOptionsForGrant - vestedOptionsSoFarForGrant
              ).toFixed(4)
            );
          } else {
            accumulatedVestedOptionsForGrant = Math.floor(
              roundOptions(
                roundPercentage(accumulatedVestingPercentageForGrant)
              ) * optionsGranted
            );
            vestedOptions =
              accumulatedVestedOptionsForGrant - vestedOptionsSoFarForGrant;
          }
          vestedOptionsSoFarForGrant += vestedOptions;
          vestingDate = addMonths(vestingStartDate, monthsPassed);
          vestingDate.setHours(0, 0, 0, 0);
          vesting.vestingDate = format(vestingDate, "yyyy-MM-dd");
          vesting.vestingOptions = vestedOptions;
          vesting.percentage =
            parseFloat((vestedOptions / optionsGranted).toFixed(4)) * 100;
          vestings.push(vesting);
        }
        vestingEvent++;
      }
    } else if (
      schedule.vestingTriggerType === TriggerType.EVENT &&
      schedule.eventTargetDate &&
      percentage
    ) {
      const vesting = { ...baseVesting };
      vesting.vestingOptions = (percentage * optionsGranted) / 100;
      new Date(schedule.eventTargetDate).setHours(0, 0, 0, 0);
      vesting.vestingDate = format(
        new Date(schedule.eventTargetDate),
        "yyyy-MM-dd"
      );
      vesting.percentage = percentage * 100;
      vestings.push(vesting);
    }
  });
  return vestings;
}

function roundPercentage(x: number) {
  return roundToPlaces(x, 10);
}

function roundOptions(x: number) {
  return roundToPlaces(x, 3);
}

function roundToPlaces(x: number, places: number) {
  const scaledNumber = x * 10 ** places;
  return Math.round(scaledNumber) / 10 ** places;
}

export default GrantVestingSchedule;
