/* eslint-disable no-control-regex */
export function _trimAll(str: string) {
  return str.trim().replace(/ +(?= )/g, "");
}

export function limitString(str: string, limit: number) {
  if (str?.length > limit) {
    return str?.substring(0, limit).concat(" . . .");
  } else {
    return str?.substring(0, limit);
  }
}

export function stringFormat(str: string) {
  return str?.replaceAll("_", " ");
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeEachWord(str: string): string {
  return str.toLowerCase().replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
}

export const stringToNumberConvertor = (formattedNumber: string) => {
  const regularString = formattedNumber.toString().replaceAll(",", "");
  if (regularString.indexOf(".") !== -1) {
    return parseFloat(regularString);
  } else return parseInt(regularString, 10);
};

export const getFormattedValue = (
  value: number,
  locale = "INR",
  isFloating = false,
  retainDigits = 4
) => {
  if (value === 0) return "0";
  if (isFloating) {
    return parseFloat(value?.toString()).toLocaleString(locale, {
      maximumFractionDigits: retainDigits,
    });
  }
  return parseInt(value?.toString(), 10).toLocaleString(locale);
};
// Regular expression to match ANSI escape codes
export function removeAnsiEscapeCodes(str: string) {
  const ansiEscapeRegex = /\u001b\[[0-9;]*m/g;
  return str.replace(ansiEscapeRegex, "");
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
